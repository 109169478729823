import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import LanguageCheckBoxGroup from 'components/Netlinking/CheckBox/LanguageCheckBox';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import CheckBox from 'components/shared/CheckBox';
import { CustomTooltip } from 'components/shared/CustomTooltip';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { Loader } from 'components/shared/Loader';
import PopoverSpotBig from 'components/shared/PopoverSpot/PopoverSpotsBig';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import {
  PAGE_NB_OPTIONS,
  PIXLINK_PUBLISH_STATUS,
  PIXLINK_REDAC_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { ConsultantSelectedSpotTable } from './ConsultantSelectedSpotTable';
import { CustomDropdownMultipleStatusSpots } from 'components/shared/DropDown/CustomDropdownStatusSpots';
import PopoverSpotMenu from 'components/shared/DropDown/NewDropDownMenu';
import { ExcelExportButtonSourcing } from 'components/shared/ExcelExport/SourcingExport/ExcelExportButtonSourcing';
import ResponsableCheckBox from 'components/Netlinking/CheckBox/ResponsableCheckBox';
import RangeDatePicker from 'components/Netlinking/Calendar/CalendarRangePicker';
import { NetlinkingCommandedRedactionStatus } from 'components/Netlinking/AutoComplete/NetlinkingCommandedRedactionStatus';
import { NetlinkingPublicationStatus } from 'components/Netlinking/AutoComplete/NetlinkingCommandedPublicationStatus';
import axiosPixLink from 'config/axiosPixLink';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { UserSessionSelector } from 'store/UserSession';
import {
  getLanguageIdByName,
  getLanguageNameById,
} from 'utils/netlinking/languages';
export default function ConsultantSelectedSpotTableControl({
  netlinkings,
  campaignFilter,
  wantedNetlinkings,
  removeNetlinkingId,
  SaveNetlinkingToCampaigns,
  sendUpdateSaveSelectedNetlinking,
  campaignStatus,
  historicBackLinkings,
  campaignType
}) {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  let { user } = useSelector(UserSessionSelector);
  const [netlinkingType, setNetlinkingType] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [filteringLoading, setFilteringLoading] = useState(false);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dataExcelToExport, setDataExcelToExport] = useState([]);
  const [filterCellSelected, setFilterCellSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(['']);
  const [arraylength, setArrayLength] = useState(-1);
  const [redacStatusFilterList, setRedacStatusFilterList] = useState([]);
  const [startSavingFilter, setStartSavingFilter] = useState(false);
  const [publicationStatusFilterList, setPublicationStatusFilterList] =
    useState([]);
  const [responsableRedactionCheckbox, setResponsableRedactionCheckbox] =
    useState('');
  const [responsablePublicationCheckbox, setResponsablePublicationCheckbox] =
    useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const sendLengthArrayShown = (length) => {
    setArrayLength(length);
  };

  const [spotsColumns, setSpotColumns] = useState(
    JSON.parse(localStorage.getItem('netlinkingColumnBigCache')) !== null
      ? JSON.parse(localStorage.getItem('netlinkingColumnBigCache'))
        .updatedColumns
      : [
        { name: 'domain', label: t('Domaine'), visible: true },
        { name: 'themesList', label: t('Thématique'), visible: false },
        { name: 'topicsList', label: t('Topic Majestic'), visible: false },
        { name: 'kwPositioned', label: t('Kw positionés'), visible: false },
        { name: 'traffic', label: t('Trafic'), visible: false },
        { name: 'tF', label: t('TF'), visible: false },
        { name: 'cF', label: t('CF'), visible: false },
        { name: 'tarifReel', label: `${t('Tarif')} ` + ' ' + t('réel'), visible: true },
        { name: 'tarifHr', label: `${t('Tarif')} ` + ' HR', visible: false },
        { name: 'tarifRc', label: `${t('Tarif')} ` + ' RC', visible: false },
        {
          name: 'platformHR',
          label: t('Tarif plateforme') + ' RH',
          visible: false,
        },
        {
          name: 'platformRC',
          label: t('Tarif plateforme') + ' RC',
          visible: false,
        },
        { name: 'contact', label: t('Contact'), visible: false },

        { name: 'redactionState', label: t('Etat rédaction'), visible: true },
        {
          name: 'publicationState',
          label: t('Etat publication'),
          visible: true,
        },

        {
          name: 'programationDate',
          label: t('Date de programmation'),
          visible: true,
        },
        {
          name: 'contactDate',
          label: t('Contacté le'),
          visible: true,
        },
        {
          name: 'publicationDate',
          label: t('Date de publication estimé'),
          visible: true,
        },

        { name: 'articleLink', label: t('lien article'), visible: true },
        { name: 'spotLink', label: t('lien du spot'), visible: true },

        { name: 'ancreLink', label: t('ancre de lien'), visible: true },
        { name: 'inseredLink', label: t('lien à insérer'), visible: true },

        {
          name: 'redacResponsible',
          label: t('Responsable rédaction'),
          visible: true,
        },
        {
          name: 'publicationResponsible',
          label: t('Responsable publication'),
          visible: true,
        },
      ]
  );

  const filterStatusMenuList = [
    { text: t('Tous les status'), status: '' },
    {
      text: t('A valider Pixalione'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE,
    },
    {
      text: t('A valider client'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
    },
    {
      text: t('Validé client'),
      status: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
    },
    { text: t('A commander'), status: PIXLINK_STATUS.TO_BE_COMMANDED },
    { text: t('Refusé client'), status: PIXLINK_STATUS.REFUSED_CLIENT },
    { text: t('Refusé Pixalione'), status: PIXLINK_STATUS.REFUSED_PIXALIONE },
  ];
  function getStatusText(status) {
    const item = filterStatusMenuList.find((item) => item.status === status);
    return item ? item.text : 'Status not found';
  }

  const removeString = (stringToRemove) => {
    setSelectedStatus(
      selectedStatus.filter((string) => string !== stringToRemove)
    );
  };

  const toggleFilterCellSelected = () => {
    setFilterCellSelected((prev) => !prev);
  };
  function handleDataExcelToExport(data) {
    setDataExcelToExport(data);
  }

  const handleClickeStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleCloseStatusFilter = () => {
    setAnchorEl(null);
  };

  const handleClickStatusFilter = (status) => {
    const selectedIndex = selectedStatus.indexOf(status);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStatus.slice(1));
    } else if (selectedIndex === selectedStatus.length - 1) {
      newSelected = newSelected.concat(selectedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStatus.slice(0, selectedIndex),
        selectedStatus.slice(selectedIndex + 1)
      );
    }
    if (newSelected.includes('') && !selectedStatus.includes('')) {
      newSelected = [''];
    } else {
      newSelected = newSelected.filter((e) => e !== '');
    }

    setSelectedStatus(newSelected);
  };

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }

  function handleThemeChange(newTheme) {
    if (
      newTheme !== null &&
      newTheme !== undefined &&
      !netlinkingTheme.includes(newTheme)
    ) {
      setNetlinkingTheme([...netlinkingTheme, newTheme]);
    }
  }

  function handleActiveAdvancedFilter() {
    setActiveAdvancedFilter(!activeAdvancedFilter);
  }

  function removeTheme(theme) {
    const updatedThemes = netlinkingTheme.filter((t) => t !== theme);
    setNetlinkingTheme(updatedThemes);
  }
  function handleLanguageCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox4 &&
      !newStates.checkbox5 &&
      !newStates.checkbox6 &&
      !newStates.checkboxExternal
    ) {
      setNetlinkingLanguage('');
    }
    if (newStates.checkbox1) {
      setNetlinkingLanguage(6);
    } else if (newStates.checkbox2) {
      setNetlinkingLanguage(1);
    } else if (newStates.checkbox3) {
      setNetlinkingLanguage(3);
    } else if (newStates.checkbox4) {
      setNetlinkingLanguage(2);
    } else if (newStates.checkbox5) {
      setNetlinkingLanguage(4);
    } else if (newStates.checkbox6) {
      setNetlinkingLanguage(5);
    } else if (newStates.checkboxExternal) {
      setNetlinkingLanguage(getLanguageIdByName(newStates.checkboxExternal));
    }
  }
  async function removeLanguageFilter() {
    setNetlinkingLanguage('');
  }

  async function removeNetlinkingContactFilter() {
    setNetlinkingContactFilter('');
  }
  function runSearchFunction() {
    setFilteringLoading(true);
    searchFunction();
    setShowTable(true);
    setPopupOpen(false);
    setFilteringLoading(false);
  }
  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item?.netlinking?.type === 'redList' ||
          item?.netlinking?.type === 'liste rouge' ||
          item?.netlinking?.type === 'Liste Rouge' ||
          item?.netlinking?.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item?.netlinking?.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item?.netlinking?.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item?.netlinking?.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.netlinking.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.netlinking.themesList !== null &&
            row.netlinking.themesList
              .toLowerCase()
              .includes(value.toLowerCase().trim())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.netlinking.tF >= TFValues[0] &&
          item.netlinking.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.netlinking.trafic >= TraficValues[0] &&
          item.netlinking.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.netlinking.tarifHr >= TarifHRValues[0] &&
          item.netlinking.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.netlinking.tarifRc >= TarifRCValues[0] &&
          item.netlinking.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.netlinking.kwPositioned >= Kwvalues[0] &&
          item.netlinking.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.netlinking.contact != null &&
          item.netlinking.contact != undefined &&
          item.netlinking.contact.includes(netlinkingContactFilter)
      );
    }
    let tmpResponsibleRedacFilter = tmpNetlinkingContactFilter;
    if (responsableRedactionCheckbox != '') {
      tmpResponsibleRedacFilter = tmpResponsibleRedacFilter.filter(
        (item) =>
          item.responsibleRedaction != null &&
          item.responsibleRedaction != undefined &&
          item.responsibleRedaction === responsableRedactionCheckbox
      );
    }
    let tmpResponsiblePublicationFilter = tmpResponsibleRedacFilter;
    if (responsablePublicationCheckbox != '') {
      tmpResponsiblePublicationFilter = tmpResponsiblePublicationFilter.filter(
        (item) =>
          item.responsiblePublication != null &&
          item.responsiblePublication != undefined &&
          item.responsiblePublication === responsablePublicationCheckbox
      );
    }

    const redactStatusFilterListRaw = redacStatusFilterList.map(
      (item) => item.status
    );

    const publicationStatusFilterListRaw = publicationStatusFilterList.map(
      (item) => item.status
    );

    let tmpRedacStatusArrayFilter = tmpResponsiblePublicationFilter;
    if (redactStatusFilterListRaw.length > 0) {
      tmpRedacStatusArrayFilter = tmpRedacStatusArrayFilter
        .map((item) =>
          !item.redactionStatus || item.redactionStatus == ''
            ? { ...item, redactionStatus: PIXLINK_REDAC_STATUS.TO_BE_LAUNCHED }
            : item
        )
        .filter((obj) =>
          redactStatusFilterListRaw.some(
            (status) => status === obj.redactionStatus
          )
        );
    }

    let tmpPublicationStatusArrayFilter = tmpRedacStatusArrayFilter;
    if (publicationStatusFilterListRaw.length > 0) {
      tmpPublicationStatusArrayFilter = tmpPublicationStatusArrayFilter
        .map((item) =>
          !item.publicationStatus || item.publicationStatus == ''
            ? {
              ...item,
              publicationStatus: PIXLINK_PUBLISH_STATUS.TO_BE_COMMANDED,
            }
            : item
        )
        .filter((obj) =>
          publicationStatusFilterListRaw.some(
            (status) => status === obj.publicationStatus
          )
        );
    }
    let tmpDateArrayFilter = tmpPublicationStatusArrayFilter;
    if (selectedEndDate !== null && selectedEndDate !== null)
      tmpDateArrayFilter = tmpDateArrayFilter.filter((obj) => {
        const objDate = new Date(obj.programmationDate);
        return (
          objDate >= new Date(selectedStartDate) &&
          new Date(objDate <= selectedEndDate)
        );
      });

    setNetlinkingFilteredList(tmpDateArrayFilter);
    if (startSavingFilter === true) {
      createOrUpdateNetlinkingFilter();
    }
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);

    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Français';
        break;
      case 1:
        result = 'English';
        break;
      case 2:
        result = 'Deutsch';
        break;
      case 3:
        result = 'Español';
        break;
      case 4:
        result = 'Italiano';
        break;
      case 5:
        result = 'Português';
        break;
      default:
        result = 'Français';
        break;
    }

    return result;
  }

  function handleReponsableRedactionCheckboxChange(newStates) {
    if (!newStates.checkbox1 && !newStates.checkbox2) {
      setResponsableRedactionCheckbox('');
    }
    if (newStates.checkbox1) {
      setResponsableRedactionCheckbox('Paris');
    } else if (newStates.checkbox2) {
      setResponsableRedactionCheckbox('Thailand');
    }
  }
  function handleReponsablePublicationCheckboxChange(newStates) {
    if (!newStates.checkbox1 && !newStates.checkbox2) {
      setResponsablePublicationCheckbox('');
    }
    if (newStates.checkbox1) {
      setResponsablePublicationCheckbox('Paris');
    } else if (newStates.checkbox2) {
      setResponsablePublicationCheckbox('Thailand');
    }
  }
  const handleDatesChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  function handleRedacListChange(newStatus) {
    if (
      newStatus !== null &&
      newStatus !== undefined &&
      !redacStatusFilterList.includes(newStatus)
    ) {
      setRedacStatusFilterList([...redacStatusFilterList, newStatus]);
    }
  }
  function handlePublicationListChange(newStatus) {
    if (
      newStatus !== null &&
      newStatus !== undefined &&
      !publicationStatusFilterList.includes(newStatus)
    ) {
      setPublicationStatusFilterList([
        ...publicationStatusFilterList,
        newStatus,
      ]);
    }
  }

  function removeRedacList(status) {
    const updateStatus = redacStatusFilterList.filter((t) => t !== status);
    setRedacStatusFilterList(updateStatus);
  }

  function removePublicationList(status) {
    const updateStatus = publicationStatusFilterList.filter(
      (t) => t !== status
    );
    setPublicationStatusFilterList(updateStatus);
  }
  const createOrUpdateNetlinkingFilter = async () => {
    try {
      const netlinkingFilterCacheTobeCreate = {
        pageType: 'selected',
        campaignId: id,
        userId: user.id,
        netlinkingType: netlinkingType,
        minTFValue: minTFValue,
        maxTFValue: maxTFValue,
        minTraficValue: minTraficValue,
        maxTraficValue: maxTraficValue,
        minTarifHRValue: minTarifHRValue,
        maxTarifHRValue: maxTarifHRValue,
        minTarifRCValue: minTarifRCValue,
        maxTarifRCValue: maxTarifRCValue,
        minKwValue: minKwValue,
        maxkwValue: maxKwValue,
        responsibleRedaction: responsablePublicationCheckbox,
        responsiblePublication: responsableRedactionCheckbox,
        startDatePublication: selectedStartDate,
        endDatePulication: selectedEndDate,
        themes: netlinkingTheme.join('/'),
        languageId:
          typeof netlinkingLanguage === 'string'
            ? getLanguageIdByName(netlinkingLanguage)
            : netlinkingLanguage,
        redactionStatuslist: redacStatusFilterList
          .map((item) => item.status)
          .join('/'),
        publicationStatusList: publicationStatusFilterList
          .map((item) => item.status)
          .join('/'),
      };
      const response = await axiosPixLink.post(
        '/netlinking-filter/create-update',
        netlinkingFilterCacheTobeCreate
      );
      // Handle success response here
    } catch (error) {
      console.error('Error:', error);
      // Handle error here
    }
  };

  const handleValueNetlinkingContactFilter = (value) => {
    setNetlinkingContactFilter(value);
  };
  useEffect(() => {
    searchFunction();
  }, [
    redacStatusFilterList,
    publicationStatusFilterList,
    responsablePublicationCheckbox,
    responsableRedactionCheckbox,
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    netlinkingContactFilter,
    selectedEndDate,
    selectedStartDate,
  ]);

  useEffect(() => {
    setNetlinkingList(netlinkings);
    setNetlinkingFilteredList(netlinkings);
  }, [netlinkings]);

  useEffect(() => {
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setFilteringLoading(true);
    }
    setFilteringLoading(true);

    window.dispatchEvent(new Event('locationchange'));

    setTimeout(async () => {
      await axiosPixLink
        .get('/netlinking-filter/get', {
          params: {
            pageType: 'selected',
            campaignId: id,
            userId: user.id,
          },
        })
        .then((response) => {
          if (response.data != null) {
            let CampaignFilterClean = response.data;
            CampaignFilterClean.minKwValue = CampaignFilterClean.minKwValue
              ? CampaignFilterClean.minKwValue
              : 0;
            CampaignFilterClean.maxkwValue = CampaignFilterClean.maxkwValue
              ? CampaignFilterClean.maxkwValue
              : Infinity;
            CampaignFilterClean.minTFValue = CampaignFilterClean.minTFValue
              ? CampaignFilterClean.minTFValue
              : 0;
            CampaignFilterClean.maxTFValue = CampaignFilterClean.maxTFValue
              ? CampaignFilterClean.maxTFValue
              : Infinity;
            CampaignFilterClean.maxTarifRCValue =
              CampaignFilterClean.maxTarifRCValue
                ? CampaignFilterClean.maxTarifRCValue
                : Infinity;
            CampaignFilterClean.minTarifRCValue =
              CampaignFilterClean.minTarifRCValue
                ? CampaignFilterClean.minTarifRCValue
                : 0;
            CampaignFilterClean.minTraficValue =
              CampaignFilterClean.minTraficValue
                ? CampaignFilterClean.minTraficValue
                : 0;
            CampaignFilterClean.maxTraficValue =
              CampaignFilterClean.maxTraficValue
                ? CampaignFilterClean.maxTraficValue
                : Infinity;
            CampaignFilterClean.minTarifHRValue =
              CampaignFilterClean.minTarifHRValue
                ? CampaignFilterClean.minTarifHRValue
                : 0;
            CampaignFilterClean.maxTarifHRValue =
              CampaignFilterClean.maxTarifHRValue
                ? CampaignFilterClean.maxTarifHRValue
                : Infinity;
            //keywordPositionned
            if (
              CampaignFilterClean.minKwValue === 0 &&
              CampaignFilterClean.maxkwValue === Infinity
            ) {
              setKwvalues([0, 0]);
              setMaxKwValue(0);
              setMinKwValue(0);
            } else {
              setKwvalues([
                CampaignFilterClean.minKwValue,
                CampaignFilterClean.maxkwValue,
              ]);
              setMaxKwValue(CampaignFilterClean.minKwValue);
              setMinKwValue(CampaignFilterClean.maxkwValue);
            }

            //TfValues
            if (
              CampaignFilterClean.minTFValue === 0 &&
              CampaignFilterClean.maxTFValue === Infinity
            ) {
              setTFValues([0, 0]);
              setMaxTFValue(0);
              setMinTFValue(0);
            } else {
              setTFValues([
                CampaignFilterClean.minTFValue,
                CampaignFilterClean.maxTFValue,
              ]);
              setMaxTFValue(CampaignFilterClean.maxTFValue);
              setMinTFValue(CampaignFilterClean.minTFValue);
            }

            //TarifRc
            if (
              CampaignFilterClean.minTarifRCValue === 0 &&
              CampaignFilterClean.maxTarifRCValue === Infinity
            ) {
              setTarifRCValues([0, 0]);
              setMaxTarifRCValue(0);
              setMinTarifRCValue(0);
            } else {
              setTarifRCValues([
                CampaignFilterClean.minTarifRCValue,
                CampaignFilterClean.maxTarifRCValue,
              ]);
              setMaxTarifRCValue(CampaignFilterClean.maxTarifRCValue);
              setMinTarifRCValue(CampaignFilterClean.minTarifRCValue);
            }

            //Traffic
            if (
              CampaignFilterClean.minTraficValue === 0 &&
              CampaignFilterClean.maxTraficValue === Infinity
            ) {
              setTraficValues([0, 0]);
              setMaxTraficValue(0);
              setMinTraficValue(0);
            } else {
              setTraficValues([
                CampaignFilterClean.minTraficValue,
                CampaignFilterClean.maxTraficValue,
              ]);
              setMinTraficValue(CampaignFilterClean.minTraficValue);
              setMaxTraficValue(CampaignFilterClean.maxTraficValue);
            }
            //TarifHr
            if (
              CampaignFilterClean.minTarifHRValue === 0 &&
              CampaignFilterClean.maxTarifHRValue === Infinity
            ) {
              setTarifHRValues([0, 0]);
              setMaxTarifHRValue(0);
              setMinTarifHRValue(0);
            } else {
              setTarifHRValues([
                CampaignFilterClean.minTarifHRValue,
                CampaignFilterClean.maxTarifHRValue,
              ]);
              setMaxTarifHRValue(CampaignFilterClean.maxTarifHRValue);
              setMinTarifHRValue(CampaignFilterClean.minTarifHRValue);
            }

            /*-----------------------------------------------------------------*/
            if (CampaignFilterClean.languageId) {
              setNetlinkingLanguage(CampaignFilterClean.languageId);
            }

            if (
              !(
                CampaignFilterClean.themes === '' ||
                CampaignFilterClean.themes.split('/').length === 0 ||
                (CampaignFilterClean.themes.split('/').length === 1 &&
                  CampaignFilterClean.themes.split('/').includes(''))
              )
            )
              setNetlinkingTheme(CampaignFilterClean.themes.split('/'));

            setNetlinkingType(CampaignFilterClean.netlinkingType);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle error here
        });

      setFilteringLoading(false);
      setStartSavingFilter(true);
    }, '1000');

    /*  }, '100');*/
  }, []);
  const handleCheckBoxColumnsToggle = (index) => {
    const updatedColumns = [...spotsColumns];
    updatedColumns[index].visible = !updatedColumns[index].visible;
    localStorage.setItem(
      'netlinkingColumnBigCache',
      JSON.stringify({
        updatedColumns,
      })
    );
    setSpotColumns(updatedColumns);
  };

  const mergeAndFilterNetlinkings = (
    wantedNetlinkings,
    existingNetlinkings
  ) => {
    // Create an object to store unique netlinkings based on their id
    const uniqueIds = {};

    // Iterate over existingNetlinkings and mark their ids as seen
    existingNetlinkings.forEach((netlinking) => {
      uniqueIds[netlinking.id] = true;
    });

    // Filter wantedNetlinkings to remove duplicates
    const filteredWantedNetlinkings = wantedNetlinkings.filter(
      (netlinking) => !uniqueIds[netlinking.id]
    );

    // Combine existingNetlinkings with the filtered wantedNetlinkings
    const mergedNetlinkings = [
      ...filteredWantedNetlinkings,
      ...existingNetlinkings,
    ];

    return mergedNetlinkings;
  };

  return (
    <div>
      <div className="content-center">
        <div className="box-header-top-dm header-info">
          {true &&
            (filteringLoading ? (
              <h1>{'XXX' + ' ' + t('Spot(s) trouvé(s)')}</h1>
            ) : (
              <h1>
                {netlinkingFilteredList.length === 0
                  ? '0' + ' ' + t('Spot(s) trouvé(s)')
                  : arraylength > -1
                    ? arraylength + ' ' + t('Spot(s) trouvé(s)')
                    : netlinkingFilteredList.length +
                    ' ' +
                    t('Spot(s) trouvé(s)')}
              </h1>
            ))}
        </div>
        <CustomTooltip
          title={
            <span>
              <p>
                {t('Gris')} : {t('spot pas sélectionné par consultant')}
              </p>
              <p>
                <strong>{t('Gris en Gras')} </strong>:{' '}
                {t('campagne premium ou à valider par SA')}
              </p>
              <p>
                {t('Noir')} :{' '}
                {t(
                  'spot sélectionné par consultant,à valider super consultant'
                )}
              </p>
              <p>
                {t('Orange')} :{' '}
                {t('Validé SA ou super consultant, à traiter client')}
              </p>
              <p>
                {t('Bleu')} : {t('Validé client, à trier Consultant')}
              </p>
              <p>
                {t('Vert')} : {t('Validé client, à commander')}
              </p>
              <p>
                {t('Rouge')} : {t('Refusé client')}
              </p>
              <p>
                {t('Violet')} : {t('Refusé super admin ou super consultant')}
              </p>
              <p>
                {t('Rouge bordeaux en gras')} : {t('Spot de la liste rouge')}
              </p>
            </span>
          }
          placement="top"
        >
          <button
            type="button"
            className="btn-circle"
            style={{ marginLeft: '5px' }}
          ></button>
        </CustomTooltip>
        <br></br>
        <PopoverSpotMenu
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        >
          <button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={(e) => handleClickeStatusFilter('-1', e)}
            style={{
              padding: '5px',
              border: 'none',
              background: 'none',
              marginBottom: '25px',
            }}
          >
            <span>
              <span className="theme-container-grey click-pointer">
                <span>{t('Status spot:')}</span>
              </span>
            </span>
          </button>
          {selectedStatus.length > 0 && (
            <>
              {selectedStatus.map((status, index) => (
                <span key={index} className="theme-container click-pointer">
                  <span>{t(getStatusText(status))}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeString(status);
                    }}
                  >
                    x
                  </button>
                </span>
              ))}
            </>
          )}
        </PopoverSpotMenu>
        {/*<CustomDropdownMultipleStatusSpots
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        />*/}
        {true && filteringLoading && <Loader />}
        {true && (
          <div>
            <div className="input-container-excel">
              <div className="input-group input-cs w-80">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Rechercher par nom de domaine')}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center item-per-table">
                <span className="me-2">{t('Afficher les résultats')}</span>
                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}

                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <PopoverSpotBig
                content={spotsColumns}
                isSelected={filterCellSelected}
                onCheckboxToggle={handleCheckBoxColumnsToggle}
              >
                <span
                  className={
                    filterCellSelected
                      ? 'bt-circle-edit-selected'
                      : 'bt-circle-edit'
                  }
                >
                  <img src="Images/edit-cell.png" alt="icon-edit-cell" />
                </span>
              </PopoverSpotBig>
              <div
                className={
                  netlinkingLanguage !== '' ||
                    netlinkingType !== '' ||
                    netlinkingTheme.length > 0 ||
                    TFValues[0] !== 0 ||
                    TFValues[1] !== 0 ||
                    TraficValues[0] !== 0 ||
                    TraficValues[1] !== 0 ||
                    Kwvalues[0] !== 0 ||
                    Kwvalues[1] !== 0 ||
                    TarifRCValues[0] !== 0 ||
                    TarifRCValues[1] !== 0 ||
                    TarifHRValues[0] !== 0 ||
                    TarifHRValues[1] !== 0 ||
                    responsablePublicationCheckbox !== '' ||
                    responsableRedactionCheckbox !== '' ||
                    redacStatusFilterList.length !== 0 ||
                    publicationStatusFilterList.length !== 0 ||
                    selectedStartDate !== null ||
                    selectedEndDate !== null
                    ? 'bt-filter-circle-selected vertical-col-filter'
                    : 'bt-filter-circle vertical-col-filter-not-selected'
                }
                onClick={() => {
                  setActiveAdvancedFilter(false);
                  setPopupOpen(!popupOpen);
                }}
              ></div>

              {true && netlinkings && netlinkings.length > 0 && (
                <ExcelExportButtonSourcing
                  netlinkingData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.netlinking.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingFilteredListData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.netlinking.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingExportList={dataExcelToExport}
                ></ExcelExportButtonSourcing>
              )}
            </div>
            {popupOpen && (
              <Modal
                isOpen={popupOpen}
                toggle={() => setPopupOpen(!popupOpen)}
                backdrop={true}
              >
                <div>
                  <div className="col-12 col-md-12 box-screen">
                    <div className="box-img-bg bg-3" />
                    <div className="box-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                        className="btn-close btnClose"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="header-title">
                        {t('Paramétres de recherche')}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">
                          {t('Type de spots')} :
                        </div>
                        <TypeCheckBoxGroup
                          onCheckboxChange={handleTypeCheckboxChange}
                          initialStateString={netlinkingType}
                        />
                      </div>

                      <div className="row row-mb">
                        <div className="col-12 col-md-6">
                          <div className="label-title">{t('Thématique')} :</div>
                          <ThemeSearchField
                            onThemeChange={handleThemeChange}
                            highlightedOptions={netlinkingTheme}
                          />
                        </div>
                      </div>
                      <div className="theme-list-container-filter">
                        {netlinkingTheme.map((theme, index) => (
                          <span key={index} className="theme-container">
                            <span>{theme}</span>
                            <button onClick={() => removeTheme(theme)}>
                              x
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">{t('Langue')} :</div>
                        <LanguageCheckBoxGroup
                          onCheckboxChange={handleLanguageCheckboxChange}
                          initialStateString={netlinkingLanguage}
                        />
                      </div>
                      <div class="row row-mb">
                        <div class="label-title">
                          <span class="label-title-filtre">
                            {t('Filtre avancé')} :
                          </span>

                          <CheckBox
                            name="Checkbox 1"
                            tick={activeAdvancedFilter || true}
                            onCheck={handleActiveAdvancedFilter}
                          />
                        </div>
                      </div>

                      {(activeAdvancedFilter || true) && (
                        <div>
                          <div className="row row-mb">
                            {/* First Slider */}
                            <SliderComponent
                              SliderName={'TF'}
                              values={TFValues}
                              handleSliderChange={handleSliderTFChange}
                              minValue={minTFValue}
                              maxValue={maxTFValue}
                              handleMinInputChange={handleMinTFInputChange}
                              handleMaxInputChange={handleMaxTFInputChange}
                              SliderLabel={'TF'}
                            />

                            {/* Second Slider */}

                            <SliderComponent
                              SliderName={t('Trafic')}
                              values={TraficValues}
                              handleSliderChange={handleSliderTraficChange}
                              minValue={minTraficValue}
                              maxValue={maxTraficValue}
                              handleMinInputChange={handleMinTraficInputChange}
                              handleMaxInputChange={handleMaxTraficInputChange}
                              SliderLabel={'Trafic'}
                            />

                            {/* Third Slider */}
                            <SliderComponent
                              SliderName={t('Mots clés positionnés')}
                              values={Kwvalues}
                              handleSliderChange={handleSliderChange}
                              minValue={minKwValue}
                              maxValue={maxKwValue}
                              handleMinInputChange={handleMinInputChange}
                              handleMaxInputChange={handleMaxInputChange}
                              SliderLabel={'keywords positioned'}
                            />
                          </div>
                          <div className="row row-mb">
                            <SliderComponent
                              SliderName={t('Tarif') + ' RC'}
                              values={TarifRCValues}
                              handleSliderChange={handleSliderTarifRCChange}
                              minValue={minTarifRCValue}
                              maxValue={maxTarifRCValue}
                              handleMinInputChange={handleMinTarifRCInputChange}
                              handleMaxInputChange={handleMaxTarifRCInputChange}
                              SliderLabel={'Tarif RC'}
                            />{' '}
                            <SliderComponent
                              SliderName={t('Tarif') + ' HR'}
                              values={TarifHRValues}
                              handleSliderChange={handleSliderTarifHRChange}
                              minValue={minTarifHRValue}
                              maxValue={maxTarifHRValue}
                              handleMinInputChange={handleMinTarifHRInputChange}
                              handleMaxInputChange={handleMaxTarifHRInputChange}
                              SliderLabel={'Tarif HR'}
                            />
                          </div>
                          <div className="row row-mb">
                            <div className="col-12 col-md-6 d-flex align-items-center">
                              <div>
                                <div className="label-title">
                                  {t('Responsable redaction')} :
                                </div>
                                <ResponsableCheckBox
                                  onCheckboxChange={
                                    handleReponsableRedactionCheckboxChange
                                  }
                                  initialStateString={
                                    responsableRedactionCheckbox
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                              <div>
                                <div className="label-title">
                                  {t('Responsable publication')} :
                                </div>
                                <ResponsableCheckBox
                                  onCheckboxChange={
                                    handleReponsablePublicationCheckboxChange
                                  }
                                  initialStateString={
                                    responsablePublicationCheckbox
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row row-mb">
                            <div className="col-12 col-md-6">
                              <div className="label-title">
                                {t('Date publication')} :
                              </div>
                              <RangeDatePicker
                                initialStartDate={selectedStartDate}
                                initialEndDate={selectedEndDate}
                                onDatesChange={handleDatesChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="label-title">
                                {t('Etat rédaction')} :
                              </div>
                              <NetlinkingCommandedRedactionStatus
                                onListChange={handleRedacListChange}
                                highlightedOptions={redacStatusFilterList}
                              />
                            </div>
                            <div className="theme-list-container-filter">
                              {redacStatusFilterList.map((item, index) => (
                                <span key={index} className="theme-container">
                                  <span>{item.text}</span>
                                  <button onClick={() => removeRedacList(item)}>
                                    x
                                  </button>
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="label-title">
                                {t('Etat publication')} :
                              </div>
                              <NetlinkingPublicationStatus
                                onListChange={handlePublicationListChange}
                                highlightedOptions={publicationStatusFilterList}
                              />
                            </div>
                            <div className="theme-list-container-filter">
                              {publicationStatusFilterList.map(
                                (theme, index) => (
                                  <span key={index} className="theme-container">
                                    <span>{theme.text}</span>
                                    <button
                                      onClick={() =>
                                        removePublicationList(theme)
                                      }
                                    >
                                      x
                                    </button>
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="box-button-bt">
                        <div style={{ margin: '20px' }}>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={runSearchFunction}
                          >
                            {t('Chercher')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {(netlinkingLanguage !== '' ||
              netlinkingType !== '' ||
              netlinkingTheme.length > 0 ||
              TFValues[0] !== 0 ||
              TFValues[1] !== 0 ||
              TraficValues[0] !== 0 ||
              TraficValues[1] !== 0 ||
              Kwvalues[0] !== 0 ||
              Kwvalues[1] !== 0 ||
              TarifRCValues[0] !== 0 ||
              TarifRCValues[1] !== 0 ||
              TarifHRValues[0] !== 0 ||
              TarifHRValues[1] !== 0 ||
              netlinkingContactFilter != '' ||
              responsablePublicationCheckbox !== '' ||
              responsableRedactionCheckbox !== '' ||
              redacStatusFilterList.length !== 0 ||
              publicationStatusFilterList.length !== 0 ||
              selectedStartDate ||
              selectedEndDate) && (
                <div class="bright-yellow-div">
                  {netlinkingType !== '' && (
                    <>
                      <b>Type:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(netlinkingType)}
                        </span>
                        <button
                          onClick={() => {
                            setNetlinkingType('');
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingLanguage !== '' && (
                    <>
                      <b>Langue:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {t(getLanguageNameById(netlinkingLanguage))}
                        </span>
                        <button
                          onClick={() => {
                            removeLanguageFilter();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingTheme.length > 0 && (
                    <>
                      <b>{t('Theme')}:</b>{' '}
                      {netlinkingTheme.map((theme, index) => (
                        <span
                          key={index}
                          className="theme-container click-pointer"
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          <span>{theme}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removeTheme(theme);
                            }}
                          >
                            x
                          </button>
                        </span>
                      ))}
                    </>
                  )}
                  {(TFValues[0] !== 0 || TFValues[1] !== 0) && (
                    <>
                      <b>TF:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TFValues[0]}-{TFValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTFValues([0, 0]);
                            setMinTFValue(0);
                            setMaxTFValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TraficValues[0] !== 0 || TraficValues[1] !== 0) && (
                    <>
                      <b>Trafic:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TraficValues[0]}-{TraficValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTraficValues([0, 0]);
                            setMinTraficValue(0);
                            setMaxTraficValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(Kwvalues[0] !== 0 || Kwvalues[1] !== 0) && (
                    <>
                      <b>Keyword Positioned:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {Kwvalues[0]}-{Kwvalues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setKwvalues([0, 0]);
                            setMinKwValue(0);
                            setMaxKwValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifRCValues[0] !== 0 || TarifRCValues[1] !== 0) && (
                    <>
                      <b>Tarif RC:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifRCValues[0]}-{TarifRCValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifRCValues([0, 0]);
                            setMinTarifRCValue(0);
                            setMaxTarifRCValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {(TarifHRValues[0] !== 0 || TarifHRValues[1] !== 0) && (
                    <>
                      <b>Tarif HR:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {TarifHRValues[0]}-{TarifHRValues[1]}
                        </span>
                        <button
                          onClick={() => {
                            setTarifHRValues([0, 0]);
                            setMinTarifHRValue(0);
                            setMaxTarifHRValue(0);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {netlinkingContactFilter !== '' && (
                    <>
                      <b>Contact:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {netlinkingContactFilter}
                        </span>
                        <button
                          onClick={() => {
                            removeNetlinkingContactFilter();
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {redacStatusFilterList.length > 0 && (
                    <>
                      <b>{t('Etat rédaction')}:</b>{' '}
                      {redacStatusFilterList.map((item, index) => (
                        <span
                          key={index}
                          className="theme-container click-pointer"
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          <span>{item.text}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removeRedacList(item);
                            }}
                          >
                            x
                          </button>
                        </span>
                      ))}
                    </>
                  )}
                  {publicationStatusFilterList.length > 0 && (
                    <>
                      <b>{t('Etat publication')}:</b>{' '}
                      {publicationStatusFilterList.map((item, index) => (
                        <span
                          key={index}
                          className="theme-container click-pointer"
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          <span>{item.text}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removePublicationList(item);
                            }}
                          >
                            x
                          </button>
                        </span>
                      ))}
                    </>
                  )}
                  {responsableRedactionCheckbox !== '' && (
                    <>
                      <b>{'Responsable redaction'}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {responsableRedactionCheckbox}
                        </span>
                        <button
                          onClick={() => {
                            setResponsableRedactionCheckbox('');
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                  {responsablePublicationCheckbox !== '' && (
                    <>
                      <b>{'Responsable publication'}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {responsablePublicationCheckbox}
                        </span>
                        <button
                          onClick={() => {
                            setResponsablePublicationCheckbox('');
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}

                  {(selectedStartDate !== null || selectedEndDate !== null) && (
                    <>
                      <b>{t('Date éstimé de publication')}:</b>{' '}
                      <span className="theme-container click-pointer">
                        <span
                          onClick={() => {
                            setActiveAdvancedFilter(false);
                            setPopupOpen(!popupOpen);
                          }}
                        >
                          {selectedStartDate !== null
                            ? selectedStartDate.toLocaleDateString('fr-FR')
                            : ''}
                          {' - '}
                          {selectedEndDate !== null
                            ? selectedEndDate.toLocaleDateString('fr-FR')
                            : ''}
                        </span>
                        <button
                          onClick={() => {
                            setSelectedStartDate(null);
                            setSelectedEndDate(null);
                          }}
                        >
                          x
                        </button>
                      </span>
                    </>
                  )}
                </div>
              )}
            <ConsultantSelectedSpotTable
              shownNetlinkingFilteredList={netlinkingFilteredList}
              nameSearch={nameSearch}
              onNetlinkingContactFilterChange={
                handleValueNetlinkingContactFilter
              }
              contactFilter={netlinkingContactFilter}
              sendNetlinkingExport={handleDataExcelToExport}
              ordersPerPage={ordersPerPage}
              removeNetlinkingId={removeNetlinkingId}
              SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
              columns={spotsColumns}
              sendUpdateSaveSelectedNetlinking={
                sendUpdateSaveSelectedNetlinking
              }
              selectedStatus={selectedStatus}
              campaignStatus={campaignStatus}
              wantedNetlinkings={wantedNetlinkings}
              sendLengthArrayShown={sendLengthArrayShown}
              historicBackLinkings={historicBackLinkings}
              campaignType={campaignType}
            />
          </div>
        )}
      </div>
    </div>
  );
}
